.change-password-form {
  .change-password-form-item {
    &:last-child {
      margin-bottom: 0;
    }
    &.change-password-form-button {
      .ant-form-item-control-input-content {
        @include flex(flex-end, center);
        .change-password-form-button-cancel {
          margin-right: 8px;
        }
      }
    }
  }
}
