.wrapper.usage-history {
  .main {
    .main-content {
      .usage-history-item {
        margin-bottom: 30px;
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        &.usage-history-title {
          .usage-history-title-name {
            margin-bottom: 10px;
            @include set_font(16px, 600, 1);
          }
        }
        &.customer-info {
          .customer-info-title {
            margin-bottom: 10px;
            @include set_font(16px, 600, 1);
          }
          .customer-info-table {
            .customer-info-table-row {
              .customer-info-table-cell {
                padding: 10px 0;
                &:first-child {
                  width: 150px;
                  padding-right: 10px;
                }
                .select-sim {
                  width: 100%;
                }
              }
            }
          }
        }
        &.payment-amount {
          .payment-amount-title {
            @include flex(space-between, center);
            padding-bottom: 10px;
            border-bottom: 1px solid #e0e0e0;
          }
          .payment-amount-list {
            .payment-amount-item {
              @include flex(space-between, center);
              padding: 10px 0;
            }
          }
        }
        &.usage-statistic {
          //   padding: 0;
          overflow-x: auto;
          scrollbar-color: #e0e0e0 transparent;
          max-height: 500px;
          .usage-statistic-table {
            width: 100%;
            position: relative;
            .usage-statistic-table-row {
              transition: all 0.3s ease;
              &:hover {
                background-color: #f9f9f9;
              }
              //   &:nth-child(even) {
              //     background-color: #f9f9f9;
              //   }
              &:last-child {
                .usage-statistic-table-cell {
                  border-bottom: none;
                }
              }
              .usage-statistic-table-header {
                position: sticky;
                z-index: 1;
                top: -24px;
                white-space: nowrap;
                padding: 10px;
                border: 1px solid #e0e0e0;
                font-weight: 600;
                background-color: #fff;
                color: #1c77ff;
                // &:first-child {
                //   padding-left: 24px;
                // }
                // &:last-child {
                //   padding-right: 24px;
                // }
              }
              .usage-statistic-table-cell {
                padding: 10px;
                border-bottom: 1px solid #e0e0e0;
                text-align: center;
                &:first-child {
                  padding-left: 24px;
                  //   width: 150px;
                  //   padding-right: 10px;
                }
                &:last-child {
                  padding-right: 24px;
                }
                // &.title-money {
                //   color: red;
                // }
                &.value-money {
                  font-weight: 600;
                  color: red;
                }
                .usage-statistic-table-cell-icon {
                  display: block;
                  margin-bottom: 10px;
                  @include set_font(30px, 400, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
