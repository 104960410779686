.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.5);
  @include flex(center, center);
  z-index: 999999;
  user-select: none;
  .ant-spin-dot-item {
    background-color: inear-gradient(to right, #4f5bb4, #359dd4) !important;
  }
}
.default-layout {
  min-height: 100vh;
  background: none;
  .sidebar {
    background-color: #fff;
    &.ant-layout-sider {
      height: 100%;
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      .ant-menu {
        .ant-menu-item {
          @include flex(flex-start, center);
          &.ant-menu-item-selected {
            background-color: #b0d2ff;
            color: #0062b3;
          }
        }
      }
    }
    .sidebar-logo {
      width: 150px;
      height: 150px;
      display: block;
      margin: 0 auto;
      padding: 16px 0;
      img {
        object-fit: contain;
      }
    }
  }
  .main {
    // width: 100% !important;
    background: none;
    .header {
      @include flex(space-between, center);
      padding: 0 16px;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: #fff;
      transition: all 0.3s ease;
      @include mq_min_992 {
        padding: 0 32px;
        margin-left: 200px;
      }
      &.extend {
        @include mq_min_992 {
          margin-left: 0;
        }
      }
      .header-button {
        // padding: 16px;
        .header-button-icon {
          font-size: 24px;
        }
      }
      .header-dropdown {
        cursor: pointer;
        &.user-avt {
          .icon-user {
            font-size: 24px;
          }
        }
      }
    }
    .content {
      //   padding: 16px;
      padding: 12px 16px;
      background-color: #f5f5f5;
      transition: all 0.3s ease;
      @include mq_min_992 {
        padding: 24px 32px;
        border-radius: 6px;
        margin-left: 200px;
      }
      &.extend {
        @include mq_min_992 {
          margin-left: 0;
          border-radius: 0;
        }
      }
    }
    .main-footer {
      background-color: #fff;
      span {
        display: block;
        text-align: center;
      }
    }
  }
}
.ant-dropdown {
  .ant-dropdown-menu {
    .dropdown-item-icon {
      font-size: 16px;
    }
    .dropdown-item-link {
      font-size: 16px;
    }
    .dropdown-item-flag {
      width: 16px;
      aspect-ratio: 3/2;
      img {
        object-fit: contain;
      }
    }
    .dropdown-item {
      .dropdown-item-icon {
        margin-inline-end: 8px;
      }
    }
  }
}
.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  @include mq_min_992 {
    display: none;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.ant-modal-root {
  //   .ant-modal-mask {
  .ant-modal-wrap {
    // background-color: red;
    .logout-modal {
      .ant-modal-content {
        .ant-modal-body {
          @include flex(flex-end, center);
          gap: 10px;
        }
      }
    }
  }
  //   }
}
