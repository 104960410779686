.wrapper.register-service-package {
  .main {
    .main-content {
      .register-service-package-list {
        .register-item {
          margin-bottom: 30px;
          padding: 24px;
          border-radius: 8px;
          background-color: #fff;
          .register-item-title {
            margin-bottom: 20px;
            @include set_font(16px, 500, 1.5);
          }
          .register-item-content {
            .register-item-content-step {
              padding-bottom: 25px;
              border-bottom: 1px solid #aaa;
              .ant-steps-item {
                &.ant-steps-item-finish {
                  cursor: pointer;
                  .ant-steps-item-tail {
                    &::after {
                      background-color: #52c41a;
                    }
                  }
                  .ant-steps-icon {
                    color: #52c41a;
                  }
                }
                .ant-steps-icon {
                  display: block;
                }
              }
            }
            .register-item-content-register {
              margin-top: 25px;
              padding-bottom: 25px;
              border-bottom: 1px solid #aaa;
              @include mq_min_992 {
                max-width: 100%;
              }
              .ant-radio-group {
                width: 100%;
                @include flex(flex-start, normal);
                flex-wrap: wrap;
                gap: 8px;
                .ant-radio-wrapper {
                  width: calc(50% - 4px);
                  align-items: center;
                  margin: 0;
                  padding: 10px;
                  border: 1px solid #aaa;
                  border-radius: 6px;
                  background-color: #fff;
                  transition: all 0.3s ease;
                  &.ant-radio-wrapper-checked {
                    border-color: #1677ff;
                    background-color: #1677ff;
                    color: #fff;
                    .ant-radio-inner {
                      border-color: #fff;
                      background-color: #fff;
                      &::after {
                        background-color: #1677ff;
                      }
                    }
                  }
                }
              }
              .service-package {
                margin-top: 20px;
                padding-top: 25px;
                border-top: 1px solid #aaa;
                .service-package-item {
                  &:not(:first-child) {
                    margin-top: 15px;
                  }
                  .service-package-label {
                    padding-bottom: 10px;
                    line-height: 1.5;
                  }
                  .service-package-content {
                    @include flex(flex-start, normal);
                    flex-wrap: wrap;
                    gap: 8px;
                    .service-package-content-item {
                      width: calc(50% - 4px);
                      padding: 8px;
                      border: 1px solid #d9d9d9;
                      border-radius: 6px;
                      transition: all 0.3s ease;
                      cursor: pointer;
                      //   user-select: none;
                      &:hover {
                        background-color: #f5f5f5;
                      }
                    }
                  }
                }
              }
              .network-transfer {
                margin-top: 20px;
                .network-transfer-item {
                  margin-bottom: 8px;
                  .network-transfer-item-input {
                    &.date-picker {
                      width: 100%;
                    }
                  }
                }
              }
              .register-item-content-register-confirm {
                @include flex(space-between, center);
                gap: 8px;
                @include mq_min_992 {
                  justify-content: flex-start;
                }
                &:not(:last-child) {
                  margin-bottom: 24px;
                }
                .register-confirm-label {
                  min-width: 20%;
                  max-width: 40%;
                  line-height: 1.5;
                  @include mq_min_992 {
                    max-width: 20%;
                  }
                }
                .register-confirm-content {
                  min-width: 40%;
                  max-width: 40%;
                  padding: 10px 15px;
                  border-radius: 6px;
                  line-height: 1.5;
                  background-color: #f5f5f5;
                  @include mq_min_992 {
                    min-width: 20%;
                    max-width: 20%;
                  }
                }
              }
            }
            .register-item-content-price {
              margin-top: 25px;
              padding-bottom: 25px;
              border-bottom: 1px solid #aaa;
              .price-item {
                @include flex(space-between, center);
                flex-wrap: wrap;
                margin-bottom: 15px;
                padding: 10px;
                border: 1px solid transparent;
                border-radius: 6px;
                background-color: #f5f5f5;
                transition: all 0.3s ease;
                &:hover {
                  border-color: #aaa;
                }
                &:last-child {
                  margin-bottom: 0;
                }
                //   border-bottom: 1px solid #aaa;
                &.has-collapse {
                  display: block;
                  .ant-collapse-header {
                    padding: 0;
                    .ant-collapse-expand-icon {
                      .collapse-total {
                        min-width: 60px;
                        margin-right: 15px;
                        text-align: right;
                        line-height: 1;
                      }
                      .collapse-icon {
                        width: 20px;
                        height: 20px;
                        @include flex(center, center);
                        padding: 5px;
                        border-radius: 50%;
                        // background-color: #1677ff;
                        // color: #fff;
                        i {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                  .ant-collapse-content-box {
                    padding: 0;
                    //   .price-item-content {
                    //     padding-left: 35px;
                    //   }
                    .price-item-content-number {
                      min-width: calc(60px + 25px);
                      margin-right: 35px;
                      text-align: right;
                    }
                  }
                }
                .price-item-label {
                  line-height: 1.6;
                }
                .price-item-number {
                  min-width: calc(60px + 25px);
                  margin-right: 35px;
                  text-align: right;
                  line-height: 1.6;
                }
                .price-item-content {
                  width: 100%;
                  margin-top: 10px;
                  padding-left: 15px;
                  .price-item-content-item {
                    @include flex(space-between, center);
                    margin-bottom: 10px;
                    line-height: 1.2;
                    .price-item-content-label {
                      max-width: 75%;
                      line-height: 1.2;
                    }
                  }
                }
                .bold {
                  font-weight: 600;
                }
                .italic {
                  font-style: italic;
                }
              }
            }
            .register-item-content-button {
              margin-top: 25px;
              @include flex(flex-start, normal);
              gap: 8px;
              .ant-btn {
                width: calc(100% - 4px);
              }
            }
          }
          .register-item-instructions {
            margin-bottom: 20px;
            .ant-collapse-header {
              //   align-items: center;
              flex-direction: column;
              //   margin-bottom: 20px;
              padding: 0;
              .ant-collapse-expand-icon {
                display: inline-block;
                padding: 0;
                .ant-collapse-arrow {
                  @include set_font(14px, 400, 1);
                  color: #1677ff;
                }
              }
              .ant-collapse-header-text {
                // @include set_font(16px, 500, 1.5);
                .instructions-subtitle {
                  font-style: italic;
                }
              }
            }
            .ant-collapse-content {
              margin-top: 20px;
              .ant-collapse-content-box {
                padding: 0;
                .instructions-list {
                  .instructions-item {
                    &:not(:last-child) {
                      margin-bottom: 15px;
                    }
                    .instructions-item-title {
                      .instructions-item-title-text {
                        padding-left: 10px;
                        font-weight: 500;
                        font-style: italic;
                      }
                    }
                    .instructions-item-content {
                      padding-left: 20px;
                      list-style-type: circle;
                    }
                  }
                }
              }
            }
          }
          .register-item-button {
            .register-item-button-link {
              display: block;
              .ant-btn {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
