.wrapper.customer {
  //   .main {
  // .main-content {
  .customer-notification {
    @include flex(flex-start, center);
    margin-bottom: 30px;
    padding: 20px 10px;
    border-radius: 8px;
    // @include set_font(16px, 400, 1);
    background-color: #fee4c6;
    @include mq_min_992 {
      padding: 20px 30px;
    }
    &.error {
      background-color: #fef2f0;
      .customer-notification-icon {
        color: #f94d50;
      }
    }
    &.pending {
      background-color: #e6f4ff;
      .customer-notification-icon {
        color: #1c77ff;
      }
    }
    .customer-notification-icon {
      display: inline-block;
      margin-right: 10px;
      font-size: 25px;
      color: #faad13;
    }
  }
  //   .customer-item-info {
  //     padding: 20px 10px;
  //     border-radius: 30px;
  //     background-color: #d6e6eb;
  //     @include mq_min_992 {
  //       padding: 20px 30px;
  //     }
  //     .customer-item-info-header {
  //       @include flex(space-between, center);
  //       gap: 20px;
  //       flex-wrap: wrap;
  //       margin-bottom: 20px;
  //       .customer-item-info-title {
  //         @include set_font(20px, 600, 1);
  //       }
  //       .customer-item-info-edit {
  //         padding: 5px 50px;
  //         border-radius: 30px;
  //         @include set_font(16px, 400, 1);
  //         background-color: #4533f8;
  //         color: #fff;
  //         cursor: pointer;
  //       }
  //       .customer-item-info-action {
  //         .customer-item-info-action-button {
  //           padding: 5px 20px;
  //           border-radius: 30px;
  //           @include set_font(16px, 400, 1);
  //           cursor: pointer;
  //         }
  //         .customer-item-info-cancel {
  //           display: inline-block;
  //           margin-right: 20px;
  //           background-color: #f00;
  //           color: #fff;
  //         }
  //         .customer-item-info-save {
  //           background-color: #0f0;
  //           color: #fff;
  //         }
  //       }
  //     }
  //     .customer-item-info-detail {
  //       width: 100%;
  //       .form-group {
  //         &.info {
  //           .form-item {
  //             @include flex(flex-start, normal);
  //             gap: 15px 30px;
  //             flex-wrap: wrap;
  //             .customer-info {
  //               width: 100%;
  //               @include flex(flex-start, center);
  //               gap: 10px;
  //               flex-wrap: wrap;
  //               padding: 10px;
  //               border-radius: 6px;
  //               @include set_font(16px, 400, 1);
  //               background-color: #fff;
  //               @include mq_min_992 {
  //                 width: calc(50% - 15px);
  //               }
  //               &:last-child {
  //                 margin-bottom: 0;
  //               }
  //               .customer-info-label {
  //                 width: 100%;
  //                 @include set_font(16px, 500, 1);
  //               }
  //               .customer-info-content {
  //                 width: 100%;
  //                 @include set_font(16px, 400, 1.2);
  //                 @include truncate_text(1);
  //               }
  //             }
  //           }
  //         }
  //         .form-item {
  //           padding: 20px 0;
  //           border-bottom: 1px solid #fff;
  //           &:first-child {
  //             border-top: 1px solid #fff;
  //           }
  //           &:last-child {
  //             border-bottom: none;
  //           }
  //           .form-item-title {
  //             width: 100%;
  //             @include set_font(18px, 500, 1);
  //             margin-bottom: 20px;
  //           }
  //           .customer-form-input {
  //             width: 100%;
  //           }
  //           .customer-form-upload {
  //             @include flex(flex-start, normal);
  //             flex-wrap: wrap;
  //             gap: 15px;
  //             @include mq_min_768 {
  //               gap: 100px;
  //             }
  //             .ant-upload-wrapper {
  //               width: 100%;
  //               @include mq_min_768 {
  //                 max-width: 40%;
  //               }
  //               @include mq_min_992 {
  //                 max-width: 35%;
  //               }
  //               @include mq_min_1280 {
  //                 max-width: 30%;
  //               }
  //               @include mq_min_1400 {
  //                 max-width: 25%;
  //               }
  //             }
  //             .customer-form-upload-sample {
  //               width: 100%;
  //               max-height: 350px;
  //               aspect-ratio: 3/2;
  //               @include mq_min_768 {
  //                 max-width: 200px;
  //                 max-height: 200px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  .main-content {
    .info-list {
      .info-item {
        margin-bottom: 30px;
        padding: 24px;
        border-radius: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(2) {
          padding-top: 0;
        }
        // @include mq_min_992 {
        //   padding: 24px;
        // }
        background-color: #fff;
        .customer-info {
          //   @include flex(flex-start, flex-start);
          //   row-gap: 8px;
          //   flex-wrap: wrap;
          //   margin: 0 -4px;
          //   padding: 12px;
          //   border-radius: 8px;
          background-color: #fff;
          @include mq_min_992 {
            // row-gap: 32px;
            // padding: 24px;
            // margin: 0 -16px;
          }
          //   .customer-info-image {
          //     padding: 0 4px;
          //     @include mq_min_992 {
          //       padding: 0 16px;
          //     }
          //     .customer-info-avt {
          //       width: 100%;
          //       //   height: 100%;
          //       max-width: 250px;
          //       aspect-ratio: 1;
          //       margin: 0 auto;
          //       position: relative;
          //       top: 50%;
          //       transform: translateY(-50%);
          //       @include mq_min_768 {
          //         margin: initial;
          //       }
          //       img {
          //         border-radius: 6px;
          //       }
          //     }
          //   }
          //   .customer-info-main {
          //     padding: 0 4px;
          //     @include mq_min_992 {
          //       padding: 0 16px;
          //     }
          .customer-info-main-title {
            margin-bottom: 20px;
            @include set_font(16px, 500, 1.5);
          }
          .customer-info-main-content {
            .customer-info-main-content-item {
              padding-bottom: 16px;
              &:last-child {
                padding-bottom: 0;
              }
              .customer-info-main-content-item-title {
                color: rgba(0, 0, 0, 0.45);
              }
              .customer-info-main-content-item-text {
                word-break: break-all;
              }
            }
          }
          //   }
        }
        .customer-info-tabs {
          .customer-form {
            .customer-form-upload {
              .customer-form-upload-sample {
                width: 100%;
                max-height: 350px;
                aspect-ratio: 3/2;
                margin-top: 10px;
                @include mq_min_768 {
                  max-width: 200px;
                  max-height: 200px;
                }
                img {
                  object-fit: contain;
                }
              }
            }
            .customer-info-button {
              .customer-form-edit {
                margin-right: 10px;
              }
              .customer-form-prev {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  // }
  //   }
}
