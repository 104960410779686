.wrapper.register {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: normal;
  flex-wrap: wrap;
  position: relative;
  .register-language {
    position: absolute;
    top: 20px;
    right: 20px;
    @include set_font(16px, 400, 1);
    .register-language-icon {
      width: 30px;
      height: 20px;
    }
  }
  .register-welcome {
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    padding: 20px;
    background-color: #196ee5;
    color: #fff;
    @include mq_min_992 {
      min-height: 100vh;
      width: 50%;
    }
    .register-welcome-header {
      //   @include flex(center, center);
      //   @include set_font(20px, 500, 1);
      //   .register-welcome-header-logo {
      // margin-right: 20px;
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
      padding: 25px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      img {
        object-fit: contain;
      }
      //   }
    }
    .register-welcome-title {
      margin-bottom: 20px;
      @include set_font(30px, 600, 1.2);
      text-align: center;
    }
    .register-welcome-description {
      @include set_font(16px, 400, 1.2);
      text-align: center;
    }
  }
  .register-form {
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    padding: 20px;
    @include mq_min_992 {
      width: 50%;
      min-height: 100vh;
    }
    .register-form-title {
      @include set_font(38px, 600, 1);
      text-align: center;
      margin-bottom: 20px;
    }
    .register-form-login {
      @include flex(center, center);
      flex-wrap: wrap;
      gap: 4px;
      margin-bottom: 20px;
      .register-form-login-link {
        color: #4d8bff;
        text-decoration: underline;
      }
    }
    .register-form-main {
      width: 100%;
      margin: 0 auto;
      @include mq_min_992 {
        max-width: 60%;
      }
      .register-form-item {
        // &.password {
        //   margin-bottom: 10px;
        // }
        label {
          width: 100%;
          @include set_font(16px, 600, 1);
        }
        .register-form-input {
          padding: 7px 11px;
          //   border-bottom-left-radius: 0;
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
          input#password {
            // remove background color for autofill
            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
          }
        }
        .register-form-label {
          width: 100%;
          @include flex(space-between, center);
          flex-wrap: wrap;
          gap: 10px;
          .register-form-label-otp {
            text-decoration: underline;
            color: #f00;
            cursor: pointer;
          }
        }
        .register-form-item-require {
          font-style: italic;
        }
        .register-form-item-checkbox {
          font-weight: 400;
          .register-form-item-link {
            text-decoration: underline;
            color: #4d8bff;
          }
        }
      }
      .register-submit-button {
        display: block;
        margin: 24px auto;
      }
    }
  }
  //   .register-form {
  //     .register-form-item {
  //       &.password {
  //         margin-bottom: 10px;
  //       }
  //       label {
  //         width: 100%;
  //         @include set_font(16px, 600, 1);
  //       }
  //       .register-form-input {
  //         padding: 7px 11px;
  //         //   border-bottom-left-radius: 0;
  //         &:-webkit-autofill {
  //           -webkit-box-shadow: 0 0 0 30px white inset !important;
  //         }
  //         input#password {
  //           // remove background color for autofill
  //           &:-webkit-autofill {
  //             -webkit-box-shadow: 0 0 0 30px white inset !important;
  //           }
  //         }
  //       }
  //       .register-form-label {
  //         width: 100%;
  //         @include flex(space-between, center);
  //         flex-wrap: wrap;
  //         gap: 10px;
  //         .register-form-label-otp {
  //           text-decoration: underline;
  //           color: #f00;
  //           cursor: pointer;
  //         }
  //       }
  //       .register-form-item-require {
  //         font-style: italic;
  //       }
  //     }
  //     .terms {
  //       margin-top: 20px;
  //       text-align: center;
  //       @include set_font(14px, 500, 1.2);
  //       text-decoration: underline;
  //       color: #f00;
  //       cursor: pointer;
  //     }
  //     .register-submit-button {
  //       width: 100%;
  //       max-width: 70%;
  //       height: auto;
  //       display: block;
  //       margin: 24px auto;
  //       padding: 6px 15px;
  //       // border-bottom-left-radius: 0;
  //       background: #ee0233;
  //       &:hover {
  //         background: #ee0233 !important;
  //       }
  //     }
  //   }
}
