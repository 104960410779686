*,
*::before,
*::after {
  box-sizing: border-box;
}
@font-face {
  font-family: "Noto Sans JP";
  src: url("../fonts/NotoSansJP-VariableFont_wght.ttf");
}
body {
  font-family: "Noto Sans JP", sans-serif;
}
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input,
textarea {
  width: 100%;
  margin: 0;
  outline: 0;
  font-family: "Noto Sans JP", sans-serif;
}
button {
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  font-family: "Noto Sans JP", sans-serif;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  @include mq_min_576 {
    max-width: 540px;
  }
  @include mq_min_768 {
    max-width: 720px;
  }
  @include mq_min_992 {
    max-width: 960px;
  }
  @include mq_min_1280 {
    max-width: 1140px;
  }
  @include mq_min_1400 {
    max-width: 1280px;
  }
}
