.wrapper.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: normal;
  flex-wrap: wrap;
  position: relative;
  .login-language {
    position: absolute;
    top: 20px;
    right: 20px;
    @include set_font(16px, 400, 1);
    .login-language-icon {
      width: 30px;
      height: 20px;
    }
  }
  .login-welcome {
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    padding: 20px;
    background-color: #196ee5;
    color: #fff;
    @include mq_min_992 {
      min-height: 100vh;
      width: 50%;
    }
    .login-welcome-header {
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
      padding: 25px;
      border-radius: 50%;
      background-color: #fff;
      img {
        object-fit: contain;
      }
      //   @include flex(center, center);
      //   margin-bottom: 30px;
      //   @include set_font(20px, 500, 1);
      //   .login-welcome-header-logo {
      //     width: 50px;
      //     height: 50px;
      //     margin-right: 20px;
      //     border-radius: 50%;
      //     background-color: #fff;
      //     overflow: hidden;
      //     img {
      //       object-fit: contain;
      //     }
      //   }
    }
    .login-welcome-title {
      margin-bottom: 20px;
      @include set_font(30px, 600, 1.2);
      text-align: center;
    }
    .login-welcome-description {
      @include set_font(16px, 400, 1.2);
      text-align: center;
    }
  }
  .login-form {
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    padding: 20px;
    @include mq_min_992 {
      width: 50%;
      min-height: 100vh;
    }
    .login-form-title {
      @include set_font(38px, 600, 1);
      text-align: center;
      margin-bottom: 20px;
    }
    .login-form-create {
      @include flex(center, center);
      flex-wrap: wrap;
      gap: 4px;
      margin-bottom: 20px;
      .login-form-create-link {
        color: #4d8bff;
        text-decoration: underline;
      }
    }
    .login-form-main {
      width: 100%;
      margin: 0 auto;
      @include mq_min_992 {
        max-width: 60%;
      }
      .submit-button {
        display: block;
        margin: 24px auto 12px;
      }
    }
    .forgot-password {
      display: block;
      margin: 0 auto 40px;
      color: #076ee5;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  //   .login {
  //     // width: 100vw;
  //     .login-logo {
  //       width: 100px;
  //       height: 100px;
  //       margin: 0 auto 30px;
  //     }
  //     .login-form {
  //       max-width: 600px;
  //       margin: 0 auto;
  //       //   .form-item {
  //       // @include flex(center, center);
  //       //   .ant-form-item {
  //       //     .ant-form-item-row {
  //       //       justify-content: center;
  //       //     }
  //       //   }
  //       .form-item {
  //         .form-item-input {
  //           // remove background color for autofill
  //           &:-webkit-autofill {
  //             -webkit-box-shadow: 0 0 0 30px white inset !important;
  //           }
  //           input#password {
  //             // remove background color for autofill
  //             &:-webkit-autofill {
  //               -webkit-box-shadow: 0 0 0 30px white inset !important;
  //             }
  //           }
  //         }
  //       }
  //       .submit-button {
  //         display: block;
  //         // width: 100%;
  //         // max-width: 300px;
  //         margin: 24px auto;
  //         // }
  //       }
  //     }
  //     .forgot-password {
  //       display: block;
  //       margin: 0 auto 40px;
  //       text-align: center;
  //       text-decoration: underline;
  //       @include set_font(16px, 400, 1);
  //       color: #f00;
  //     }
  //     .register {
  //       display: block;
  //       text-align: center;
  //       @include set_font(16px, 400, 1.2);
  //       .register-button {
  //         color: #f00;
  //         text-decoration: underline;
  //         cursor: pointer;
  //       }
  //     }
  //   }
}
.register-modal {
  .ant-modal-title {
    margin-top: 20px;
    text-align: center;
    @include set_font(25px, 600, 1);
  }
  .return-login {
    text-align: center;
    @include set_font(16px, 400, 1);
    .return-login-button {
      color: #f00;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
