.ant-form-item-explain {
  margin-bottom: 15px;
}
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-size: 16px;
  }
}
