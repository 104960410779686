.wrapper.payment {
  .main {
    .main-content {
      .payment-list {
        .payment-item {
          &:not(.no-style) {
            margin-bottom: 30px;
            padding: 24px;
            border-radius: 8px;
            background-color: #fff;
          }
          .payment-info {
            @include mq_min_768 {
              @include flex(flex-start, normal);
              flex-wrap: wrap;
              gap: 10px;
            }
            .payment-info-item {
              width: 100%;
              //   @include flex(flex-start, normal);
              //   gap: 10px;
              //   margin: 10px 0;
              padding: 10px 0;
              border-bottom: 1px solid #ccc;
              &:last-child {
                border-bottom: none;
              }
              @include mq_min_768 {
                width: calc(100% / 5 - 10px);
                // margin: 0;
                border-bottom: none;
                // display: block;
              }
              .payment-info-item-title {
                // min-width: 35%;
                display: block;
                margin-bottom: 20px;
                // @include mq_min_992 {
                // }
              }
              .payment-info-item-content {
                @include set_font(14px, 500, 1);
                // font-weight: 500;
                // @include mq_min_992 {
                //   display: block;
                // }
                // .payment-info-item-select {
                //   width: 100%;
                // }
              }
            }
          }
          .payment-button-wrapper {
            .payment-button {
              display: block;
              margin: 0 auto;
              // padding: 5px 15px;
              // border: 2px solid #000;
              // border-radius: 30px;
            }
          }
          .payment-table {
            @include flex(flex-start, normal);
            flex-wrap: wrap;
            gap: 24px;
            // @include mq_min_992 {
            //   gap: 15px;
            // }
            .payment-table-checkout {
              width: 100%;
              padding: 24px;
              border-radius: 8px;
              background-color: #fff;
              @include mq_min_992 {
                width: calc(70% - 12px);
              }
              .table-checkout-item {
                margin-bottom: 24px;
              }
              .table-checkout-title {
                @include set_font(16px, 500, 1);
                text-align: center;
              }
              .table-checkout-money {
                .table-checkout-money-title {
                  margin-bottom: 15px;
                }
                .table-checkout-money-fixed {
                  @include flex(flex-start, normal);
                  flex-wrap: wrap;
                  gap: 15px;
                  margin-bottom: 15px;
                  .table-checkout-money-fixed-content {
                    // display: block;
                    width: 100%;
                    @include flex(center, center);
                    border: 1px solid #1677ff;
                    border-radius: 6px;
                    font-weight: 500;
                    @include mq_min_768 {
                      width: calc(50% - 7.5px);
                    }
                    .table-checkout-money-fixed-content-input {
                      width: 100%;
                      .ant-input-number-wrapper {
                        .ant-input-number {
                          .ant-input-number-input-wrap {
                            .ant-input-number-input {
                              text-align: center;
                              color: #1677ff;
                            }
                          }
                        }
                        .ant-input-number-group-addon {
                          color: #1677ff;
                        }
                      }
                    }
                  }
                  .table-checkout-money-fixed-button {
                    width: calc(50% - 7.5px);
                    @include mq_min_768 {
                      width: auto;
                    }
                  }
                }
                .table-checkout-money-suggest {
                  .table-checkout-money-suggest-title {
                    margin-bottom: 15px;
                  }
                  .table-checkout-money-suggest-list {
                    @include flex(flex-start, normal);
                    flex-wrap: wrap;
                    gap: 15px;
                    .table-checkout-money-suggest-item {
                      width: fit-content;
                      height: 32px;
                      // @include flex(center, center);
                      display: table;
                      padding-left: 11px;
                      border: 1px solid #aaa;
                      border-radius: 6px;
                      cursor: pointer;
                      transition: all 0.3s ease;
                      &:hover {
                        background-color: #f0f0f0;
                      }
                      &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                      }
                      .table-checkout-money-suggest-item-number {
                        width: 100%;
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                      }
                      .table-checkout-money-suggest-item-unit {
                        display: table-cell;
                        padding: 0 11px;
                        vertical-align: middle;
                      }
                    }
                  }
                }
              }
              .table-checkout-method {
                .table-checkout-method-title {
                  margin-bottom: 15px;
                }
                .table-checkout-method-select {
                  @include flex(flex-start, normal);
                  flex-wrap: wrap;
                  gap: 15px;
                  .ant-radio-wrapper {
                    width: 100%;
                    align-items: center;
                    margin: 0;
                    padding: 5px 10px;
                    border: 1px solid #aaa;
                    border-radius: 6px;
                    @include mq_min_768 {
                      width: calc(50% - 7.5px);
                    }
                  }
                }
              }
            }
            .payment-table-confirm {
              width: 100%;
              padding: 24px;
              border-radius: 8px;
              background-color: #fff;
              @include mq_min_992 {
                width: calc(30% - 12px);
              }
              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
              .payment-table-confirm-title {
                padding-bottom: 20px;
                border-bottom: 1px solid #aaa;
              }
              .payment-table-confirm-detail {
                padding: 20px 0;
                border-bottom: 1px solid #aaa;
                .payment-table-confirm-detail-item {
                  @include flex(space-between, normal);
                  margin-bottom: 10px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
              .payment-table-confirm-total {
                @include flex(space-between, normal);
                margin-bottom: 20px;
                padding-top: 20px;
              }
              .payment-table-confirm-terms {
                margin-bottom: 20px;
              }
              .payment-table-confirm-info {
                padding: 10px;
                border-radius: 6px;
                background-color: #c6e6dd;
                .payment-table-confirm-info-item {
                  display: block;
                  line-height: 1.5;
                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                  &.note {
                    color: #f00;
                  }
                  .payment-table-confirm-info-item-link {
                    display: block;
                    text-decoration: underline;
                    color: #1677ff;
                  }
                  .ant-collapse-item {
                    .ant-collapse-header {
                      padding: 0;
                    }
                    .ant-collapse-content {
                      .ant-collapse-content-box {
                        padding: 0;
                        .payment-table-confirm-info-item-link {
                          display: block;
                          text-decoration: underline;
                          color: #1677ff;
                        }
                      }
                    }
                  }
                }
              }
              .payment-table-confirm-button {
                margin-top: 15px;
                .payment-table-confirm-btn {
                  display: block;
                  margin: 0 auto;
                }
                .payment-table-confirm-upload {
                  .ant-upload {
                    display: block;
                    .payment-table-confirm-upload-btn {
                      display: block;
                      margin: 0 auto;
                    }
                  }
                  .ant-upload-list {
                    margin: 15px 0;
                    .ant-upload-list-item-container {
                      &:not(:first-child) {
                        margin-top: 10px;
                      }
                      .ant-upload-list-item {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
