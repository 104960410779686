.wrapper.service {
  .main {
    .main-content {
      .service-item {
        margin-bottom: 30px;
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
        .service-item-info {
          .service-item-info-phone {
            margin-bottom: 15px;
            // @include set_font(20px, 600, 1);
          }
          .service-item-info-package {
            // margin-bottom: 5px;
            // @include set_font(16px, 400, 1);
            .service-item-info-package-item {
              display: block;
              padding: 5px 0;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }
          .service-item-info-rate {
            display: block;
            margin-bottom: 30px;
          }
          .service-item-info-text {
            display: inline-block;
            margin-bottom: 15px;
            @include set_font(16px, 400, 1.2);
          }
          .service-item-info-action {
            @include flex(flex-start, center);
            flex-wrap: wrap;
            gap: 10px;
            .package-action {
              .package-action-button {
                padding: 10px 30px;
                border-radius: 6px;
                @include set_font(16px, 400, 1);
                background-color: #0601ff;
                color: #fff;
              }
            }
          }
        }
        .service-item-sim {
          .service-item-sim-title {
            margin-bottom: 25px;
            @include set_font(20px, 600, 1);
          }
          .service-item-sim-reissue {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #d9d9d9;
            .service-item-sim-reissue-title {
              margin-bottom: 15px;
              @include set_font(18px, 400, 1);
            }
            .service-item-sim-reissue-text {
              margin-bottom: 15px;
            }
            .service-item-sim-reissue-button {
              padding: 10px 30px;
              border: 1px solid #422c9f;
              border-radius: 6px;
              @include set_font(16px, 400, 1);
              color: #422c9f;
            }
          }
          .service-item-sim-storage {
            .service-item-sim-storage-title {
              margin-bottom: 15px;
              @include set_font(18px, 400, 1);
            }
            .service-item-sim-storage-text {
              margin-bottom: 15px;
            }
            .service-item-sim-storage-button {
              padding: 10px 30px;
              border-radius: 6px;
              font-size: 16px;
              font-weight: 400;
              line-height: 1;
              background-color: #0601ff;
              color: #fff;
            }
          }
        }
        .service-item-faq {
          .service-item-faq-title {
            margin-bottom: 25px;
            @include set_font(20px, 600, 1);
          }
          .service-item-faq-list {
            .service-item-faq-accordion {
              .ant-collapse-item {
                border-bottom: 1px solid #d9d9d9;
                &:last-child {
                  border-bottom: 0;
                }
                .ant-collapse-header {
                  padding: 12px 0;
                  .ant-collapse-expand-icon {
                    display: none;
                  }
                  .ant-collapse-header-text {
                    .service-item-faq-question {
                      color: #686abb;
                      .service-item-faq-question-text {
                        display: inline-block;
                        @include set_font(16px, 500, 1.2);
                        .service-item-faq-question-icon {
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
                .ant-collapse-content {
                  .ant-collapse-content-box {
                    .faq-link {
                        color: #1677ff;
                    }
                    .faq-table {
                        margin: 10px 0;
                      .faq-table-row {
                        .faq-table-cell {
                          padding: 5px 10px;
                          border: 1px solid #d9d9d9;
                          background-color: #efefef;
                          &.text-bold {
                            font-weight: 500;
                          }
                        }
                      }
                      &.faq-table-service {
                        .faq-table-service-name {
                          background-color: #001070;
                          color: #fff;
                        }
                        .faq-table-service-detail {
                          background-color: #e0efff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .service-item-support {
          //   margin: 0 -10px;
          padding-top: 30px;
          //   padding: 30px 30px 0;
          border-top: 1px solid #d9d9d9;
          //   @include mq_min_992 {
          //     margin: 0 -30px;
          //   }
          .service-item-support-link {
            padding: 10px 30px;
            border: 1px solid #dc099e;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            color: #dc099e;
          }
        }
      }
    }
  }
}
.package-action-modal {
  .ant-modal-content {
    .ant-modal-body {
      .package-action-text {
        display: inline-block;
        margin-bottom: 20px;
      }
      .package-action-radio {
        @include flex(flex-start, flex-start);
        flex-wrap: wrap;
        gap: 10px;
        .package-action-radio-button {
          border: 1px solid #d9d9d9;
          border-radius: 6px;
          transition: all 0.3s ease;
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
.reissue-modal {
  .ant-modal-content {
    .ant-modal-body {
      .reissue-text {
        display: inline-block;
        margin-bottom: 15px;
      }
      .reissue-form {
        .reissue-form-item {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .reissue-form-button {
          .ant-form-item-control-input-content {
            @include flex(flex-end, center);
            .reissue-form-button-cancel {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
