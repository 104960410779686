.wrapper.dashboard {
  .main {
    .main-content {
      .main-content-banner {
        width: 100%;
        margin-bottom: 30px;
        border-radius: 8px;
        background-color: #fff;
        overflow: hidden;
        .main-content-banner-item {
          min-height: 100px;
          height: 400px;
          img {
            object-fit: contain;
          }
        }
        .slick-dots {
          z-index: 1;
        }
      }
      .main-content-dashboard {
        .dashboard-list {
          @include flex(flex-start, normal);
          flex-wrap: wrap;
          gap: 30px;
          .dashboard-item {
            padding: 24px;
            border-radius: 8px;
            width: 100%;
            @include mq_min_768 {
              //   padding: 20px 30px;
              &:nth-child(odd) {
                width: calc(40% - 15px);
              }
              &:nth-child(even) {
                width: calc(60% - 15px);
              }
            }
            &:nth-child(1),
            &:nth-child(2) {
              background-color: #fff;
              color: #000;
            }
            &:nth-child(3),
            &:nth-child(4) {
              background-color: #daeceb;
              color: #000;
            }
            .user-info {
              .user-info-title {
                @include set_font(16px, 600, 1);
                margin-bottom: 10px;
                // color: #1677ff;
              }
              .user-info-subtitle {
                // @include set_font(16px, 400, 1);
                // color: #aaa;
                margin-bottom: 20px;
              }
              .user-info-name {
                display: block;
                margin-bottom: 15px;
                @include set_font(16px, 600, 1.2);
                text-transform: uppercase;
              }
              .user-info-detail {
                max-width: 150px;
                @include flex(center, center);
                margin: 0 auto;
                // padding: 5px 30px;
                // border: 1px solid transparent;
                // border-radius: 6px;
                // // @include set_font(16px, 400, 1);
                // text-align: center;
                // background-color: #8af0c1;
                // color: #000;
              }
            }
            .balance-info {
              .balance-info-title {
                @include set_font(16px, 600, 1);
                margin-bottom: 20px;
              }
              .balance-info-detail {
                // @include mq_min_1400 {
                @include flex(space-between, center);
                flex-wrap: wrap;
                gap: 20px;
                @include mq_min_768 {
                  justify-content: flex-start;
                }
                // }
                .balance-info-detail-amount {
                  // width: 100%;
                  @include flex(flex-start, center);
                  // margin-bottom: 20px;
                  //   @include mq_min_1400 {
                  // width: 40%;
                  // margin-bottom: 0;
                  //   }
                  .amount-money-icon {
                    margin-right: 10px;
                    font-size: 50px;
                    color: #1677ff;
                  }
                  .amount-money-text {
                    @include set_font(30px, 600, 1);
                    // color: #dedd41;
                  }
                }
                .balance-info-detail-action {
                  @include flex(flex-start, center);
                  gap: 20px;
                  .recharge {
                    // display: none;
                    @include mq_min_1400 {
                      //   display: block;
                      margin-right: 10px;
                      //   padding: 10px 20px;
                      //   border-radius: 30px;
                      //   @include set_font(18px, 400, 1);
                      //   background-color: #8af0c1;
                      //   color: #000;
                    }
                    .recharge-icon {
                      margin-right: 10px;
                    }
                  }
                  .payment {
                    display: none;
                    // padding: 10px 20px;
                    // border-radius: 6px;
                    // @include set_font(18px, 400, 1);
                    // background-color: #fff;
                    // color: #000;
                    @include mq_min_1280 {
                      display: block;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            .phone-number {
              //   padding: 20px 0;
              .phone-number-title {
                @include set_font(16px, 600, 1);
                margin-bottom: 20px;
              }
              .phone-number-list {
                .phone-number-item {
                  //   max-width: 150px;
                  display: block;
                  //   @include flex(center, center);
                  margin-bottom: 15px;
                  padding: 10px;
                  border-radius: 6px;
                  text-align: center;
                  //   @include set_font(16px, 400, 1);
                  background-color: #fff;
                  transition: all 0.3s ease;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  .phone-number-item-text {
                    display: block;
                  }
                  .phone-number-item-link {
                    display: block;
                    margin-top: 10px;
                    color: #1677ff;
                    transition: all 0.3s ease;
                    &:hover {
                      opacity: 0.8;
                    }
                  }
                }
                .phone-number-list-accordion {
                  .ant-collapse-item {
                    margin-bottom: 20px;
                    &.ant-collapse-item-active {
                      .phone-number-item {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                      }
                    }
                    .ant-collapse-header {
                      padding: 0;
                      .ant-collapse-expand-icon {
                        display: none;
                      }
                    }
                    .ant-collapse-content {
                      padding: 0;
                      &.ant-collapse-content-active {
                        margin-top: 10px;
                      }
                      .ant-collapse-content-box {
                        padding: 0;
                        .data-usage {
                          padding: 10px;
                          //   border: 1px solid #000;
                          border-radius: 0 0 6px 6px;
                          background-color: #fff;
                          .data-package-name {
                            display: inline-block;
                            margin-bottom: 15px;
                            @include set_font(16px, 600, 1);
                          }
                          .data-usage-chart {
                            @include flex(center, center);
                            margin-bottom: 20px;
                            .ant-progress-inner {
                              width: 200px !important;
                              height: 200px !important;
                              @include mq_min_768 {
                                width: 180px !important;
                                height: 180px !important;
                              }
                              @include mq_min_1280 {
                                width: 200px !important;
                                height: 200px !important;
                              }
                              .data-info {
                                @include set_font(16px, 400, 1);
                                color: #7c7c7c;
                                .data-info-text {
                                  margin-bottom: 10px;
                                }
                              }
                            }
                          }
                          .data-buy {
                            display: block;
                            margin: 0 auto 50px;
                            padding: 10px 20px;
                            border-radius: 6px;
                            // @include set_font(16px, 400, 1);
                            background-color: #4533f8;
                            color: #fff;
                            .data-buy-icon {
                              display: inline-block;
                              margin-right: 10px;
                            }
                          }
                          .check-info {
                            display: block;
                            margin-bottom: 10px;
                            // @include set_font(16px, 400, 1.2);
                            color: #000;
                            &:hover {
                              color: #4533f8;
                            }
                            .check-info-icon {
                              display: inline-block;
                              margin-right: 10px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .usage-fee {
              .usage-fee-item {
                padding-bottom: 15px;
                border-bottom: 1px solid #9b9b9b;
                &:last-child {
                  padding-bottom: 0;
                  border-bottom: none;
                }
                .monthly-payment-fee {
                  .monthly-payment-fee-title {
                    @include set_font(16px, 600, 1);
                    margin-bottom: 20px;
                  }
                  .monthly-payment-fee-slider {
                    @include flex(space-between, center);
                    margin: 0 auto;
                    padding: 5px 20px;
                    border: 2px solid #606086;
                    border-radius: 50px;
                    background-color: #fff;
                    @include mq_min_768 {
                      max-width: 80%;
                    }
                    .monthly-payment-fee-slider-button {
                      width: 25px;
                      height: 25px;
                      @include flex(center, center);
                      border-radius: 50%;
                      font-size: 15px;
                      background-color: #1c164e;
                      color: #fff;
                      &.disabled {
                        cursor: not-allowed;
                      }
                    }
                    .monthly-payment-fee-content {
                      //   @include set_font(16px, 400, 1);
                      .monthly-payment-fee-content-month {
                        font-weight: 600;
                      }
                    }
                  }
                }
                .payment-status {
                  position: relative;
                  padding: 20px 0;
                  .payment-status-label {
                    position: absolute;
                    padding: 5px 20px;
                    border-radius: 30px;
                    background-color: #8af0c1;
                  }
                  .payment-status-detail {
                    // margin-top: 30px;
                    .payment-status-detail-amount {
                      margin-bottom: 20px;
                      @include set_font(16px, 400, 1);
                      text-align: center;
                      .payment-status-detail-amount-number {
                        @include set_font(30px, 600, 1);
                      }
                    }
                    .payment-status-detail-action {
                      //   @include mq_min_1400 {
                      @include flex(center, center);
                      flex-wrap: wrap;
                      gap: 10px;
                      //   }
                      .payment-status-detail-action-item {
                        padding: 10px 20px;
                        border-radius: 6px;
                        // @include set_font(18px, 400, 1);
                        &.recharge {
                          // margin-right: 10px;
                          //   margin-bottom: 10px;
                          background-color: #4533f8;
                          color: #fff;
                          @include mq_min_1400 {
                            display: none;
                          }
                          .recharge-icon {
                            margin-right: 5px;
                            // font-size: 20px;
                          }
                        }
                        &.check-fee {
                          // margin-left: 10px;
                          border: 2px solid #606086;
                          background-color: #fff;
                          color: #000;
                          @include mq_min_1400 {
                            margin-left: 10px;
                          }
                        }
                      }
                    }
                  }
                }
                .promotion {
                  padding: 20px 0;
                  .promotion-title {
                    @include set_font(16px, 600, 1);
                    margin-bottom: 20px;
                  }
                  .promotion-list {
                    .promotion-item {
                      min-height: 100px;
                      margin-bottom: 15px;
                      padding: 10px;
                      border-radius: 6px;
                      background-color: #fff;
                      transition: all 0.3s ease;
                      &:hover {
                        color: #4533f8;
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }
                      @include mq_min_992 {
                        @include flex(flex-start, normal);
                        gap: 20px;
                      }
                      .promotion-item-image {
                        margin-bottom: 10px;
                        border-radius: 4px;
                        overflow: hidden;
                        @include mq_min_992 {
                          min-width: 20%;
                          max-width: 100px;
                          margin-bottom: 0;
                        }
                        img {
                          object-fit: contain;
                        }
                      }
                      .promotion-item-info {
                        .promotion-item-text {
                          margin-bottom: 10px;
                          @include set_font(16px, 600, 1.2);
                          white-space: pre-line;
                        }
                        .promotion-item-description {
                          @include truncate_text(3);
                          @include set_font(14px, 400, 1.5);
                          color: #7c7c7c;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
